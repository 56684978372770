import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Optional,
	Output,
	SimpleChanges,
} from '@angular/core';
import { ACCOUNT_MY_ORDERS_PATH, ACCOUNT_PATH, ChangeOrderService, EDR_DASHBOARD_PATH } from '@woolworthsnz/shop';
import {
	AppSettingsService,
	BreakPointService,
	Device,
	EditOrderNotificationComponent,
	LastOrderFulfilment,
	MaybeExternalLinkDirective,
	NotificationType,
	TrackEventDirective,
	TrackingEvent,
	TRADER_BASE_URL,
} from '@woolworthsnz/styleguide';
import { map, Observable } from 'rxjs';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
	EDRRewardsHeaderComponent,
	EverydayRewardsFacade,
	PointsBalanceHeaderType,
} from '@woolworthsnz/everyday-rewards';
import { EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

@Component({
	selector: 'global-nav-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		NgClass,
		NgTemplateOutlet,
		EditOrderNotificationComponent,
		MaybeExternalLinkDirective,
		TrackEventDirective,
		AsyncPipe,
		EDRRewardsHeaderComponent,
	],
})
export class MyAccountComponent implements OnChanges, OnInit {
	@Input() public fill: 'dark' | 'green';
	@Input() public isNavExperimentV1 = false;
	@Input() public externalLinksInCurrentTab = false;
	@Input() public refresh = false;

	@Output() public signOut = new EventEmitter<void>();
	@Output() public navItemClicked = new EventEmitter<void>();
	public trackingEvent = TrackingEvent;
	public notificationType = NotificationType;
	public manageAccountRoute = `${this.appSettingsService.getSetting('accountBaseUrl') ?? ''}/${ACCOUNT_PATH}`;

	public myOrdersRoute = `${this.traderBaseUrl ?? ''}/${ACCOUNT_PATH}/${ACCOUNT_MY_ORDERS_PATH}`;
	public changeOrderRoute = `${this.myOrdersRoute}/(account-modal:change-order)?source=myaccount`;
	public lastOrderFulfilment$: Observable<LastOrderFulfilment | undefined>;

	public isDesktop$: Observable<boolean>;
	public edrBalance$: Observable<EdrBalanceResponseV2 | undefined>;

	public pointsBalanceHeaderType = PointsBalanceHeaderType;
	public edrDashboardUrl = `/${EDR_DASHBOARD_PATH}`;

	constructor(
		private appSettingsService: AppSettingsService,
		private breakPointService: BreakPointService,
		private changeOrderService: ChangeOrderService,
		private edrFacade: EverydayRewardsFacade,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl: string
	) {}

	ngOnInit(): void {
		this.isDesktop$ = this.breakPointService.device$.pipe(
			map((device: Device) => device === Device.LARGE || device === Device.XLARGE)
		);

		this.edrBalance$ = this.edrFacade.edrBalance$;

		this.edrDashboardUrl = `${this.appSettingsService.getSetting('accountBaseUrl') ?? ''}/${EDR_DASHBOARD_PATH}`;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!!changes.refresh && changes.refresh?.currentValue === true) {
			this.assignLastOrderFulfilment();
		}
	}

	private assignLastOrderFulfilment(): void {
		this.lastOrderFulfilment$ = this.changeOrderService.getLastOrderFulfilment();
	}
}
