export const deliveryTimeSlotErrorMessage = {
	delivery_timeslot_expired:
		'The time you had to place your order has passed. \nChoose a time slot to keep shopping.',
	delivery_timeslot_expired_hww:
		'For accurate stock availability, please choose a time slot again before you keep shopping.',
	delivery_timeslot_closed_hww:
		'Sorry, this time slot is now full. For accurate stock availability, please choose a new time slot before you keep shopping.',
} as const;
export const deliveryTimeSlotTitle = {
	delivery_timeslot_expired: 'Time’s up!',
	delivery_timeslot_expired_hww: 'Time slot expired',
	delivery_timeslot_closed: 'Time slot closed',
	delivery_timeslot_expired_closing_soon: 'Time slot closing soon',
	delivery_timeslot_expired_closing_now: 'Less than 5 minutes left',
} as const;
export const deliveryTimeSlotButton = {
	delivery_timeslot_expired: 'Change time slot',
	delivery_timeslot_expired_hww: 'Choose time slot',
} as const;
