<ng-container>
	<ul class="myAccount myOrdersLinkEnabled">
		<li *ngIf="lastOrderFulfilment$ | async as lastOrderFulfilment" class="edit-order-account-menu">
			<cdx-edit-order-notification
				class="edit-order-account-menu"
				[enableAnimation]="false"
				[lastOrderFulfilment]="lastOrderFulfilment"
				[editOrderUrl]="changeOrderRoute"
			></cdx-edit-order-notification>
		</li>
		<li>
			<a
				[cdxTrackEvent]="{
					event: trackingEvent.NotificationEvent,
					type: notificationType.Global,
					name: 'account',
					value: 'My orders',
				}"
				[href]="myOrdersRoute"
				maybeExternal
				[maybeExternalCurrentTab]="true"
				i18n="@@globalNav-myAccount-myOrders"
				(click)="navItemClicked.next()"
			>
				My orders
			</a>
		</li>
		<li>
			<a
				[cdxTrackEvent]="{
					event: trackingEvent.NotificationEvent,
					type: notificationType.Global,
					name: 'everyday-rewards',
					value: 'My Everyday Rewards',
				}"
				href="{{ edrDashboardUrl }}"
				i18n="@@globalNav-myAccount-everydayRewards"
				(click)="navItemClicked.next()"
				data-cy="my-everyday-rewards"
			>
				My Everyday Rewards
			</a>
		</li>
		<li>
			<a
				[cdxTrackEvent]="{
					event: trackingEvent.NotificationEvent,
					type: notificationType.Global,
					name: 'account',
					value: 'Manage my account',
				}"
				href="{{ manageAccountRoute }}"
				i18n="@@globalNav-myAccount-manageAccountLink"
				(click)="navItemClicked.next()"
			>
				More account options
			</a>
		</li>
		<li class="sign-out-link">
			<a
				class="myAccount-signOut"
				[cdxTrackEvent]="{
					event: trackingEvent.NotificationEvent,
					type: notificationType.Global,
					name: 'account',
					value: 'Sign Out',
				}"
				(click)="signOut.next()"
				href="javascript:void(0);"
				i18n="@@globalNav-myAccount-signOut"
			>
				Sign Out
			</a>
		</li>
	</ul>
	<div *ngIf="isDesktop$ | async" class="onecard-header">
		<ng-container *ngTemplateOutlet="edrBalanceWidget"></ng-container>
	</div>
</ng-container>
<!-- TODO: https://woolworths-agile.atlassian.net/browse/CLSE-2807 replace points balance calc with new field -->
<ng-template #edrBalanceWidget>
	<edr-rewards-header
		*ngIf="edrBalance$ | async as edrBalance"
		[totalPointsRequired]="edrBalance.pointsPerReward"
		[rewardsAvailable]="edrBalance.pointsAsVoucherDollars"
		[pointsBalance]="edrBalance.pointsPerReward - edrBalance.pointsUntilNextReward"
		[redemptionPreference]="edrBalance.redemptionPreference"
		[externalLinksInCurrentTab]="externalLinksInCurrentTab"
		[headerType]="pointsBalanceHeaderType.GlobalNavigation"
	/>
</ng-template>
