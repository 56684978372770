import { DatePipe, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {
	ApiService,
	AppSettingsService,
	ChangeOrderModificationType,
	ChangeOrderProductDatalayerEvent,
	ChangeOrderSubmittedEvent,
	DatalayerService,
	FeatureService,
	LastOrderFulfilment,
	LocalStorageService,
	LoggingService,
	ModalRoutingService,
	ShopperService,
	ShopperState,
	StatefulService,
	StorageKeys,
} from '@woolworthsnz/styleguide';
import {
	BaseResponse,
	CategoryResponse,
	ContextResponse,
	OrderChangeContextResponse,
	PastOrderInitiateChangeRequest,
	PastOrderInitiateChangeResponse,
	ProductResponse,
	ProductStockCodeQuantityPriceResponse,
} from '@woolworthsnz/trader-api';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Observable, filter, of, Subscription, switchMap, take, zip } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MODAL_ROUTES } from './../shop.routes';
import { OrderService } from './order.service';
import { getDateFromOrder, getFormattedAddressFromOrder } from '../helpers';

dayjs.extend(customParseFormat);

export interface ChangeOrderState extends OrderChangeContextResponse {
	isChangingOrder: boolean;
	failedToLoadLastOrderInTrolley: boolean;
	productMap?: Map<string, ProductStockCodeQuantityPriceResponse>;
	requestedOrderId?: number;
}

@Injectable({
	providedIn: 'root',
})
export class ChangeOrderService extends StatefulService<ChangeOrderState> {
	constructor(
		private apiService: ApiService,
		private appSettingsService: AppSettingsService,
		private loggingService: LoggingService,
		private shopperService: ShopperService,
		private datalayerService: DatalayerService,
		private datePipe: DatePipe,
		private router: Router,
		private orderService: OrderService,
		private featureService: FeatureService,
		private localStorageService: LocalStorageService,
		private modalRoutingService: ModalRoutingService,
		@Inject(PLATFORM_ID) private platform: Object
	) {
		super(<ChangeOrderState>{});

		if (isPlatformServer(this.platform)) {
			return;
		}

		this.shopperService.state$.subscribe((shopperState: ShopperState) => {
			if (
				!!this.state.isChangingOrder === !!shopperState.isChangingOrder &&
				(this.state.orderId ?? null) === (shopperState.changingOrderId ?? null)
			) {
				// if the shopperState matches the internal state, no need to proceed
				return;
			}

			this.setState({
				isChangingOrder: shopperState.isChangingOrder,
				orderId: shopperState.changingOrderId,
			});

			if (!shopperState.isChangingOrder || !shopperState.changingOrderId) {
				return;
			}

			this.setRequestedOrderId(shopperState.changingOrderId);

			this.getOrderChangeContext()
				.pipe(
					catchError((error) => {
						this.loggingService.trackException(error);
						return of(error);
					})
				)
				.subscribe();
		});

		this.state$.subscribe((state) => {
			// Don't show any onboarding modal when change order modal is being shown to user or process is about to start
			// or if change order is in process
			if (!state.isChangingOrder && !this.isInitiatingChangeOrderProcess()) {
				const purchaseByQuantityEnabled = this.featureService.isFeatureEnabled(
					ContextResponse.EnabledFeaturesEnum.PurchasingUnitsOnboardingModal
				);
				const userAlreadyAcknowledged = this.localStorageService.getItem(
					StorageKeys.purchaseByQuantityAcknowledged
				);
				if (purchaseByQuantityEnabled && !userAlreadyAcknowledged) {
					this.modalRoutingService.openWithOptions(
						MODAL_ROUTES.HOME_MODAL,
						{ skipLocationChange: true },
						MODAL_ROUTES.PURCHASE_BY_QUANTITY_MODAL_PATH
					);
				}

				const changeOrderOnboardingModalEnabled = this.featureService.isFeatureEnabled(
					ContextResponse.EnabledFeaturesEnum.ChangeOrderOnboardingModal
				);
				const userAlreadyAcknowledgedChangeOrder = this.localStorageService.getItem(
					StorageKeys.changeOrderModalAcknowledged
				);
				if (changeOrderOnboardingModalEnabled && !userAlreadyAcknowledgedChangeOrder) {
					this.modalRoutingService.openWithOptions(
						MODAL_ROUTES.HOME_MODAL,
						{ skipLocationChange: true },
						MODAL_ROUTES.CHANGE_ORDER_ONBOARDING_MODAL_PATH
					);
				}
			}
		});
	}

	createProductMap(
		products: ProductStockCodeQuantityPriceResponse[] | undefined
	): Map<string, ProductStockCodeQuantityPriceResponse> {
		const productMap = new Map<string, ProductStockCodeQuantityPriceResponse>();
		if (!products) {
			return productMap;
		}

		products.forEach((product) => {
			if (product.stockCode && product) {
				productMap.set(product.stockCode, product);
			}
		});

		return productMap;
	}

	getLastOrderChangeContext(): Observable<OrderChangeContextResponse> {
		return this.apiService.get(this.appSettingsService.getEndpoint('getLastOrderChangeContext')).pipe(
			tap((lastOrderChangeContext: OrderChangeContextResponse) =>
				this.setStateFromOrderChangeContext(lastOrderChangeContext)
			),
			catchError((error) => {
				this.loggingService.trackEvent(`UI:ChangeOrder`, {
					error,
				});
				return of(error);
			})
		);
	}

	getOrderChangeContextById(orderId: number): Observable<OrderChangeContextResponse> {
		return this.apiService.get(`${this.appSettingsService.getEndpoint('getOrderChangeContext')}/${orderId}`).pipe(
			tap((orderChangeContext: OrderChangeContextResponse) => {
				this.setStateFromOrderChangeContext(orderChangeContext);
			}),
			catchError((error) => {
				this.loggingService.trackEvent(`UI:ChangeOrder`, {
					orderId,
					error,
				});
				return of(error);
			})
		);
	}

	getOrderChangeContext(): Observable<OrderChangeContextResponse> {
		return this.state.requestedOrderId
			? this.getOrderChangeContextById(this.state.requestedOrderId).pipe(
					catchError((error) => {
						this.clearRequestedOrderId();
						return of(error);
					})
				)
			: this.getLastOrderChangeContext();
	}

	setRequestedOrderId(orderId: number): void {
		this.setState({ requestedOrderId: orderId });
	}

	cancelChangeOrder(): Subscription {
		const orderId = this.state.orderId;
		return this.apiService
			.delete(this.appSettingsService.getEndpoint('cancelPastOrderChange'))
			.pipe(
				catchError((error) => {
					this.loggingService.trackEvent(`UI:ChangeOrder`, {
						error,
					});

					return of(error);
				})
			)
			.subscribe((response: BaseResponse) => {
				if (response?.isSuccessful && orderId) {
					this.datalayerService.trackChangeOrderDisabled({
						amendedTransactionId: orderId,
						eventDetails: {
							category: 'Change My Order',
							action: `${orderId}`,
							amendedTransactionId: orderId,
							label: 'Disable Change My Order',
							revisionNumber: '1',
						},
					});
				}
			});
	}

	initiateChangeOrder(): Observable<PastOrderInitiateChangeResponse> {
		if (!this.state.orderId) {
			throw new Error('Order id not set');
		}

		const request: PastOrderInitiateChangeRequest = {
			orderId: this.state.orderId,
		};

		return this.apiService.post(this.appSettingsService.getEndpoint('intiateOrderChange'), request).pipe(
			tap((response: PastOrderInitiateChangeResponse) => {
				if (!response.isSuccessful) {
					this.setState({
						failedToLoadLastOrderInTrolley: true,
					});
				} else if (this.state.orderId) {
					this.datalayerService.trackChangeOrderEnabled({
						amendedTransactionId: this.state.orderId,
						eventDetails: {
							category: 'Change My Order',
							action: `${this.state.orderId}`,
							amendedTransactionId: this.state.orderId,
							label: 'Enable Change My Order',
							revisionNumber: '1',
						},
					});
				}
			}),
			catchError((error) => {
				this.loggingService.trackEvent(`UI:ChangeOrder`, {
					error,
				});

				this.setState({
					failedToLoadLastOrderInTrolley: true,
				});

				return of(error);
			})
		);
	}

	isChangingOrder(): boolean {
		return this.state.isChangingOrder;
	}

	getProductMap(): Map<string, ProductStockCodeQuantityPriceResponse> | undefined {
		return this.state.productMap;
	}

	getOrderId(): number | undefined {
		return this.state.orderId;
	}

	getOrderTotal(): number | undefined {
		return this.state.total;
	}

	isInitiatingChangeOrderProcess(): boolean {
		return new RegExp(`\\(:${MODAL_ROUTES.ACCOUNT_MODAL_OUTLET}` + MODAL_ROUTES.CHANGE_ORDER_PATH).test(
			this.router.url
		);
	}

	trackSubmittedChangedOrder(orderId: number | undefined): ChangeOrderSubmittedEvent | undefined {
		const productMap = this.getProductMap();
		const orderTotal = this.getOrderTotal();
		const totalOrderPrice = this.orderService.getTotalOrderPrice();

		if (orderId === undefined || orderTotal === undefined || productMap === undefined) {
			return;
		}

		const orderItems = this.getChangeOrderProducts(productMap);

		const trackChangeOrderSubmittedData = {
			event: 'changeMyOrderSubmitted',
			amendedTransactionId: orderId,
			orderValueDifference: (totalOrderPrice - orderTotal).toFixed(2),
			originalOrderValue: orderTotal.toFixed(2),
			amendedOrderDetails: {
				products: orderItems,
			},
			eventDetails: {
				category: 'Change My Order',
				action: `${orderId}`,
				amendedTransactionId: orderId,
				label: 'Submitted',
				revisionNumber: '1',
			},
		};

		if (this.featureService.isFeatureEnabled(ContextResponse.EnabledFeaturesEnum.OrderConfirmation)) {
			this.datalayerService.trackChangeOrderSubmitted(trackChangeOrderSubmittedData);
		} else {
			// tracking of submitted change order is being done on the confirmation page as per recommendation by Analytics gurus
			// data is saved in a cookie and gets extracted on success confirmation page only when changing order.
			document.cookie = `trackChangeOrderSubmittedData=${JSON.stringify(trackChangeOrderSubmittedData)}; path=/;`;
		}

		return trackChangeOrderSubmittedData;
	}

	getChangeOrderProducts(
		originalOrderProducts: Map<string | undefined, ProductStockCodeQuantityPriceResponse>
	): ChangeOrderProductDatalayerEvent[] {
		const priceDecimalPoint = 2;

		const changeOrderProductDatalayerEvent: ChangeOrderProductDatalayerEvent[] = [];
		const notDeletedProducts: (string | undefined)[] = [];

		// work for products which were modified or added
		// products which exist in the original order and currently being changed order having same quantity is considered unchanged product
		// products which exist in the original order and currently being changed order having different quantity is considered modified product
		// products which doesn't exist in the original order is considered newly added product
		// order state items contains list of categorized products
		this.orderService.getOrderItems()?.forEach((categoryResponse: CategoryResponse) => {
			categoryResponse.products?.forEach((product) => {
				const originalOrderProduct = originalOrderProducts.get(product.sku);

				const productQuantity = product.quantity?.value ?? 0;
				const productPrice = product.price?.total?.replace('$', '').replace(/,/g, '') ?? '0';
				const unitMultiplyFactor = product.unit === ProductResponse.UnitEnum.Each ? 1 : 1000;

				// checking if product is a modified product based on its existence and quantity
				if (originalOrderProduct !== undefined && originalOrderProduct.quantity !== product.quantity?.value) {
					const amendedProductQuantity = productQuantity - (originalOrderProduct.quantity ?? 0);
					const amendedProductPrice = parseFloat(productPrice) - (originalOrderProduct.totalPrice ?? 0);
					changeOrderProductDatalayerEvent.push({
						id: product.sku,
						quantity: (productQuantity * unitMultiplyFactor).toFixed(),
						amendedProductPrice: amendedProductPrice.toFixed(priceDecimalPoint),
						amendedProductQuantity: (amendedProductQuantity * unitMultiplyFactor).toFixed(),
						modificationType: ChangeOrderModificationType.edit,
						unitType: product.unit,
					});
				} else if (originalOrderProduct === undefined) {
					// if doesn't exist in original order it's a new product
					changeOrderProductDatalayerEvent.push({
						id: product.sku,
						quantity: (productQuantity * unitMultiplyFactor).toFixed(),
						amendedProductPrice: parseFloat(productPrice).toFixed(priceDecimalPoint),
						amendedProductQuantity: (productQuantity * unitMultiplyFactor).toFixed(),
						modificationType: ChangeOrderModificationType.add,
						unitType: product.unit,
					});
				}
				notDeletedProducts.push(product.sku);
			});
		});

		// work for products which were removed
		// all the original order products which are missing from currently being changed order are considered as deleted products
		// notDeletedProducts contain stock code for all the products of currently being changed order as set above
		const deletedProducts = [...originalOrderProducts.keys()].filter(
			(originalOrderProductStockCode) => !notDeletedProducts.includes(originalOrderProductStockCode)
		);
		deletedProducts.forEach((deletedProductStockCode) => {
			const deletedProduct = originalOrderProducts.get(deletedProductStockCode);
			if (deletedProduct !== undefined) {
				changeOrderProductDatalayerEvent.push({
					id: deletedProduct.stockCode,
					quantity: '0',
					amendedProductPrice: deletedProduct.totalPrice?.toFixed(priceDecimalPoint),
					amendedProductQuantity: (
						-1 *
						(deletedProduct.quantity ?? 0) *
						(deletedProduct.pricingUnit === ProductResponse.UnitEnum.Each ? 1 : 1000)
					).toFixed(),
					modificationType: ChangeOrderModificationType.remove,
					unitType: deletedProduct.pricingUnit,
				});
			}
		});

		return changeOrderProductDatalayerEvent;
	}

	getLastOrderFulfilment(): Observable<LastOrderFulfilment | undefined> {
		return zip(this.shopperService.select('isLoggedIn'), this.shopperService.select('isChangingOrder')).pipe(
			filter(([isLoggedIn, isChangingOrder]) => !!(isLoggedIn && !isChangingOrder)),
			take(1),
			switchMap(() => this.getLastOrderChangeContext()),
			switchMap((lastOrder) =>
				lastOrder && lastOrder.isChangeable ? of(this.mapLastOrderFulfilment(lastOrder)) : of(undefined)
			)
		);
	}

	openChangeOrderModal(orderId: number, calledFrom?: string): void {
		this.setRequestedOrderId(orderId);
		if (calledFrom) {
			this.router.navigateByUrl('/account/orders/(account-modal:change-order)' + ('?source=' + calledFrom));
		} else {
			this.router.navigateByUrl('/account/orders/(account-modal:change-order)');
		}
	}

	private setStateFromOrderChangeContext(orderChangeContext?: OrderChangeContextResponse): void {
		if (this.state.isChangingOrder && orderChangeContext?.orderId !== this.state.orderId) {
			return;
		}
		this.setState({
			orderId: orderChangeContext?.orderId,
			isChangeable: orderChangeContext?.isChangeable,
			unchangeableOrderReason: orderChangeContext?.unchangeableOrderReason,
			deliveryMethod: orderChangeContext?.deliveryMethod,
			deliveryTimeSpan: orderChangeContext?.deliveryTimeSpan,
			deliveryStreet1: orderChangeContext?.deliveryStreet1,
			deliveryStreet2: orderChangeContext?.deliveryStreet2,
			deliverySuburb: orderChangeContext?.deliverySuburb,
			deliveryCity: orderChangeContext?.deliveryCity,
			deliveryPostalCode: orderChangeContext?.deliveryPostalCode,
			products: orderChangeContext?.products,
			productMap: this.createProductMap(orderChangeContext?.products),
			total: orderChangeContext?.total,
			requestedOrderId: undefined,
		});
	}

	private clearRequestedOrderId(): void {
		this.setState({ requestedOrderId: undefined });
	}

	private mapLastOrderFulfilment(lastOrder: OrderChangeContextResponse): LastOrderFulfilment {
		const orderDate = getDateFromOrder(lastOrder.deliveryDate);
		return {
			method: lastOrder.deliveryMethod,
			deliveryTimeSpan:
				this.datePipe.transform(orderDate, 'mediumDate') === this.datePipe.transform(new Date(), 'mediumDate')
					? `Today ${lastOrder.deliveryTimeSpan}`
					: `${
							orderDate?.toString() !== new Date('0001-01-01').toString()
								? this.datePipe.transform(orderDate, 'EEEE')
								: ''
						} ${lastOrder.deliveryTimeSpan}`.trim(),
			address: getFormattedAddressFromOrder(lastOrder),
		};
	}
}
