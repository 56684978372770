import { ProductResponse, ProductTag } from '@woolworthsnz/trader-api';
import { stockAvailabilityConstants, TealiumProduct, TealiumProductList } from '../constants';
import { ProductImpressionData } from '../models';

/**
 * Returns stringified version of whether a product is in stock or not
 */
export const isProductAvailableString = (productIsAvailable: boolean): string =>
	productIsAvailable ? stockAvailabilityConstants.inStock : stockAvailabilityConstants.outOfStock;

const isProductAvailable = (product?: ProductResponse): boolean =>
	!!product?.price?.salePrice && !!product?.quantity?.max;

const getPromoValueFromCatagory = (product: ProductImpressionData, tagtype?: ProductTag.TagTypeEnum): string => {
	switch (tagtype) {
		case ProductTag.TagTypeEnum.IsMultiBuy:
			return product.productTag?.multiBuy?.link || '';
		case ProductTag.TagTypeEnum.IsTargetedOffer:
		case ProductTag.TagTypeEnum.IsClubPrice:
			return product.productTag?.targetedOffer?.offerValue?.toString() || '';
		case ProductTag.TagTypeEnum.HasBonusPoints:
			return product.productTag?.bonusPoints?.quantity?.toString() || '';
		default:
			return '';
	}
};

export const getAnalyticsData = (
	products: ProductImpressionData[] = [],
	department?: string,
	aisle?: string,
	shelf?: string
): TealiumProductList =>
	products.reduce((productList: TealiumProductList, product: ProductImpressionData, currentIndex: number) => {
		productList.product_category_1[currentIndex] = department || '';
		productList.product_category_2[currentIndex] = aisle || '';
		productList.product_category_3[currentIndex] = shelf || '';
		productList.product_brand[currentIndex] = product.brand || '';
		productList.product_sku[currentIndex] = product.sku || '';
		productList.product_image_url[currentIndex] = product.images?.big || '';
		productList.product_name[currentIndex] = product.name || '';
		productList.product_price[currentIndex] = product.price?.originalPrice?.toString() || '';
		productList.product_sale_price[currentIndex] = product.price?.salePrice
			? product.price.salePrice.toFixed(2)
			: '';
		productList.product_stock[currentIndex] = isProductAvailableString(isProductAvailable(product));
		productList.product_dietary[currentIndex] = '';
		productList.product_quantity[currentIndex] = product.quantity?.value?.toString() || '';
		productList.search_value[currentIndex] = product.searchValue || '';
		productList.search_results_returned[currentIndex] = product.searchResultsCount || '';
		productList.product_promo_category[currentIndex] = product.productTag?.tagType || '';
		productList.product_promo_value[currentIndex] = getPromoValueFromCatagory(product, product.productTag?.tagType);

		return productList;
	}, new TealiumProductList());

const currencyToNumber = (value: string): number => Number(value.substring(1));

export const mapToTealiumProductWithCategory = (product: any, position?: number): TealiumProduct => ({
	item_id: product.sku ?? '',
	item_name: product.name ?? '',
	discount: product.price?.savePrice ?? 0,
	index: position?.toString(),
	item_brand: product.brand ?? '',
	item_category: product.category?.trim() ?? '',
	// item_category2: product?.aisle?.name,
	// item_category3: product?.shelf?.name,
	// item_variant: '',
	price: product.price?.salePrice ?? 0,
	quantity: product.quantity?.value ?? product.quantity?.quantityInOrder ?? 0,
	each_kg: product.unit?.toString() ?? '',
});

export const getBoostOfferProducts = (products: ProductResponse[]): ProductResponse[] =>
	products.filter((product) => product.productTag?.tagType === ProductTag.TagTypeEnum.IsBoostOffer);

export const getTotalBoostValue = (products: ProductResponse[]): number =>
	products.reduce(
		(acc, product: ProductResponse) => acc + (product.productTag?.boostOffer?.rewardValue.value ?? 0),
		0
	);

export const getProductsPriceTotal = (products: ProductResponse[]): number =>
	products.reduce((acc, product: ProductResponse) => acc + currencyToNumber(product.price?.total || '0'), 0);
