import { ChangeDetectionStrategy, Component, Inject, Optional, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HOW_WHERE_WHEN_PATH } from '@woolworthsnz/shop';
import {
	BreakPointService,
	CustomWindow,
	Device,
	ButtonComponent,
	TRADER_BASE_URL,
	WINDOW,
	SvgIconComponent,
	ShopperService,
	ShopperState,
} from '@woolworthsnz/styleguide';
import { map, tap, takeUntil } from 'rxjs/operators';
import { NgIf, AsyncPipe, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FulfilmentState, FulfilmentStoreService } from '@woolworthsnz/fulfilment';
import { Observable, Subject } from 'rxjs';
import { TealiumUtagService } from '@woolworthsnz/analytics';

export enum HowWhereWhenTimeSlotJourneyState {
	LoggedOut,
	NotSelected,
	Selected,
	Expired,
	Closed,
}

const journeyStateToString = {
	[HowWhereWhenTimeSlotJourneyState.NotSelected]: 'NotSelected',
	[HowWhereWhenTimeSlotJourneyState.Selected]: 'Selected',
	[HowWhereWhenTimeSlotJourneyState.Expired]: 'Expired',
	[HowWhereWhenTimeSlotJourneyState.Closed]: 'Closed',
	[HowWhereWhenTimeSlotJourneyState.LoggedOut]: 'LoggedOut',
};

@Component({
	selector: 'global-nav-how-where-when-bar',
	templateUrl: './how-where-when-bar.component.html',
	styleUrls: ['./how-where-when-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, ButtonComponent, AsyncPipe, SvgIconComponent, NgSwitch, NgSwitchCase, NgSwitchDefault],
})
export class HowWhereWhenBarComponent implements OnInit, OnDestroy {
	isMobileDevice = false;
	isLoggedInShopper = false;
	journeyState$: Observable<string>;
	fulfilmentState: FulfilmentState;
	informationMessage: string;
	actionMessage: string;
	alertMessage: string;
	iconName: string;
	locationName: string;

	private destroyed$: Subject<boolean> = new Subject();

	constructor(
		private fulfilmentStoreService: FulfilmentStoreService,
		private shopperService: ShopperService,
		private router: Router,
		private breakPointService: BreakPointService,
		private tealiumUtagService: TealiumUtagService,
		@Inject(WINDOW) private window: CustomWindow,
		@Optional() @Inject(TRADER_BASE_URL) private traderBaseUrl?: string
	) {
		this.breakPointService.device$.pipe(takeUntilDestroyed()).subscribe((device: Device) => {
			this.isMobileDevice = device === Device.MOBILE;
		});
	}

	ngOnInit(): void {
		this.shopperService.state$.pipe(takeUntil(this.destroyed$)).subscribe((r: ShopperState) => {
			this.isLoggedInShopper = (r.isLoggedIn && r.isShopper) || false;
		});

		this.journeyState$ = this.fulfilmentStoreService.state$.pipe(
			takeUntil(this.destroyed$),
			tap((state: FulfilmentState) => {
				this.fulfilmentState = state;
			}),
			map((state: FulfilmentState) => this.getTimeSlotJourneyState(state)),
			tap((journeyState: HowWhereWhenTimeSlotJourneyState) => {
				this.informationMessage = this.getInformationMessage(this.fulfilmentState, journeyState);
				this.actionMessage = this.getActionMessage(journeyState);
				this.iconName = this.getIconName(journeyState);
				this.alertMessage = this.getAlertMessage(journeyState);
			}),
			map((journeyState: HowWhereWhenTimeSlotJourneyState) => journeyStateToString[journeyState])
		);
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	handleActionButtonClick(journeyState: string): void {
		this.tealiumUtagService.link({
			tealium_event: 'select_content',
			content_type: 'AvailableToPromise',
			content_name: journeyState,
			content_component: 'HowWhereWhenBar',
		});

		if (this.traderBaseUrl) {
			this.window.location.href = `${this.traderBaseUrl}/${HOW_WHERE_WHEN_PATH}`;
		} else {
			this.router.navigateByUrl(HOW_WHERE_WHEN_PATH);
		}
	}

	private getInformationMessage(state: FulfilmentState, journeyState: HowWhereWhenTimeSlotJourneyState): string {
		let selectedDayName = '';
		const isCourier = !!state.method?.toLowerCase().includes('courier');

		if (state.selectedDate && journeyState === HowWhereWhenTimeSlotJourneyState.Selected) {
			const date = new Date(state.selectedDate);
			selectedDayName = this.getDayName(date);
		}
		if (journeyState === HowWhereWhenTimeSlotJourneyState.LoggedOut) {
			this.locationName = isCourier
				? state.address + ' area'
				: this.getStoreLocation(state.address).trim() + ' store' || '';
		}

		const formatTime = (time: string | null | undefined): string =>
			time ? time.replace(/(AM|PM)/, (match) => match.toLowerCase()) : '';

		const informationMessages = {
			[HowWhereWhenTimeSlotJourneyState.LoggedOut]: `Prices and stock availability may vary by location. You're seeing information for the `,
			[HowWhereWhenTimeSlotJourneyState.NotSelected]:
				'Prices and stock availability may vary by location. For accurate information, ',
			[HowWhereWhenTimeSlotJourneyState.Selected]: `Chosen time: ${selectedDayName}, ${formatTime(state.startTime)} to ${formatTime(state.endTime)}. `,
			[HowWhereWhenTimeSlotJourneyState.Expired]: 'For accurate stock availability, please ',
			[HowWhereWhenTimeSlotJourneyState.Closed]: 'For accurate stock availability, please ',
		};

		return (
			informationMessages[journeyState] ||
			'Prices and stock availability may vary by location. For accurate information, '
		);
	}

	private getActionMessage(journeyState: HowWhereWhenTimeSlotJourneyState): string {
		const actionMessages = {
			[HowWhereWhenTimeSlotJourneyState.LoggedOut]: 'Change location',
			[HowWhereWhenTimeSlotJourneyState.NotSelected]: 'choose your location and time slot',
			[HowWhereWhenTimeSlotJourneyState.Selected]: 'Change time slot',
			[HowWhereWhenTimeSlotJourneyState.Expired]: 'choose a time slot',
			[HowWhereWhenTimeSlotJourneyState.Closed]: 'choose a time slot',
		};

		return actionMessages[journeyState] || 'choose your location and time slot';
	}

	private getIconName(journeyState: HowWhereWhenTimeSlotJourneyState): string {
		const iconNames = {
			[HowWhereWhenTimeSlotJourneyState.LoggedOut]: 'location-pin',
			[HowWhereWhenTimeSlotJourneyState.NotSelected]: 'location-pin',
			[HowWhereWhenTimeSlotJourneyState.Selected]: 'wall-clock',
			[HowWhereWhenTimeSlotJourneyState.Expired]: 'alert',
			[HowWhereWhenTimeSlotJourneyState.Closed]: 'alert',
		};

		return iconNames[journeyState] || 'location-pin';
	}

	private getAlertMessage(journeyState: HowWhereWhenTimeSlotJourneyState): string {
		if (journeyState === HowWhereWhenTimeSlotJourneyState.Closed) {
			return 'Your time slot has closed';
		} else if (journeyState === HowWhereWhenTimeSlotJourneyState.Expired) {
			return 'Time slot expired';
		}
		return '';
	}

	private getDayName(date: Date): string {
		const today = new Date();

		return date.toDateString() === today.toDateString()
			? 'Today'
			: date.toLocaleDateString('en-US', { weekday: 'long' });
	}

	private getStoreLocation(storeName: string | undefined): string {
		if (!storeName) {
			return '';
		}
		const storeParts = storeName.split(' ');
		return ' ' + (storeParts.length > 1 ? storeParts.slice(1).join(' ') : storeName);
	}

	private getTimeSlotJourneyState(state: FulfilmentState): HowWhereWhenTimeSlotJourneyState {
		const hasTimeSelection = !!state.startTime?.trim() && !!state.endTime?.trim();
		const hasTimeslotExpired = !!state.currentReservationAlertsFired?.expired;
		const hasTimeslotClosed = !!state.currentReservationAlertsFired?.closed;

		if (!this.isLoggedInShopper) {
			return HowWhereWhenTimeSlotJourneyState.LoggedOut;
		}
		if (hasTimeslotClosed) {
			return HowWhereWhenTimeSlotJourneyState.Closed;
		} else if (hasTimeslotExpired) {
			return HowWhereWhenTimeSlotJourneyState.Expired;
		} else if (hasTimeSelection) {
			return HowWhereWhenTimeSlotJourneyState.Selected;
		}
		return HowWhereWhenTimeSlotJourneyState.NotSelected;
	}
}
